import PageContainerFull from "../components/layout/PageContainerFull";
import PageSection from "../components/layout/PageSection";
import PageTitle from "../components/layout/PageTitle";
import {useForm} from "react-hook-form";
import Card from "../components/forms/Card";
import FormGroup from "../components/forms/FormGroup";
import Input from "../components/forms/FormEl";
import Button from "../components/forms/Button";
import DeleteGenericModal from "../modals/DeleteGenericModal";
import useDeleteModal from "../modals/useDeleteModal";
import axios from "axios";
import {Apis} from "../util/data";
import image from "../img/bannerpromsalud.png"
import logo from "../img/logo.png"
import useWindowDimensions from "../hooks/useWindowDimensions";
import {errorToast, successToast} from "../components/layout/ToastStyles";

const DeletePage = () => {
  const {
    register,
    handleSubmit
  } = useForm();

  const { height } = useWindowDimensions();

  const deleteAccount = body => axios.post(Apis.PROD + '/api/v1/auth/delete',body,{
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(()=>{successToast("Cuenta borrada correctamente")})
    .catch(()=>{errorToast("Usuario o contraseña invalido")})

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Realmente desea eliminar esta cuenta?',
    action: deleteAccount
  })

  const onSubmit = (params) => {
    openDeleteModal(params)
  }

  return(
      <PageContainerFull style={{padding: 0}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: 'center',height: '100%'}}>
          <div style={{padding: 10, height: height, display: "flex", flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>
            <img style={{height: 120,width: 120}} src={logo} alt={'logo'}/>
            <Card padding style={{width: '350px'}}>
              <PageSection>
                <PageTitle title={"Eliminar cuenta"}/>
              </PageSection>
              <PageSection>
              <FormGroup>
                <label>Correo electrónico</label>
                <Input type='text' {...register("email")}/>
              </FormGroup>
              <FormGroup>
                <label>Contraseña</label>
                <Input type='password' {...register("contrasena")}/>
              </FormGroup>
              </PageSection>
              <PageSection>
                <Button onClick={handleSubmit(onSubmit)} variant={'primary'}>
                  Eliminar
                </Button>
              </PageSection>
            </Card>
          </div>
          <img style={{overflow: 'hidden', height: height}} src={image} alt={'imagen'}/>
        </div>
        <DeleteGenericModal {...wrapper2}/>
      </PageContainerFull>
  )
}

export default DeletePage
